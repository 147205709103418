import React from 'react';
import Layout from 'components/layout';
import HomePage from '../modules/Home';
import { graphql } from 'gatsby';
import categoryModule from 'services/category';
import { HreflangHelmet } from '../../srcv2/common/Seo/HreflangHelmet/HreflangHelmet';

const seo = {
  description:
    "Cервис денежных переводов KoronaPay позволяет перевести деньги без открытия счета из Европы в Россию и в другие страны онлайн и наличными в пунктах выдачи",
  title:
    'Перевести деньги из Европы онлайн — Международные денежные переводы KoronaPay',
  keywords: ''
};

const IndexPage = ({ location, data }) => {
  const articles =
    data.allStrapiArticle.nodes.filter(article => article.show_on_home_page) ||
    [];
  const categoryData = categoryModule.formatAllCategories({
    articles,
    to: '/help',
    catSlugName: 'cat_slug',
    titleName: 'title',
    slugName: 'slug'
  });

  return (
    <>
      <HreflangHelmet location={location} pageType={'default'}/>

      <Layout location={location} seo={{ canonical: `${location.origin}${location.pathname}` ,...seo}}>
        <HomePage categoryData={categoryData} />
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query AllStrapiArticleRu {
    allStrapiArticle {
      nodes {
        articles_group {
          title
          id
        }
        title
        show_on_home_page
        help_category {
          cat_slug
        }
        slug
      }
    }
  }
`;
